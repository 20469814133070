<template>
    <div class="mobileBanner">
        <img v-lazy="require('@/assets/images/index/mobile/mobileCom.png')" alt="">
        <p class="title">
            中小跨境电商营销第一“站”
        </p>
        <p class="infos">
            FunPinPin是基于营销平台原生打造的建站工具，
            专注为中小跨境电商提供集“云端建站、网站运营、智能营销、资金管理”为一体的一站式出海解决方案。
            平台首创0月租、0佣金的免费服务模式，并依托于全链条海外营销服务领跑者“木瓜移动”的孵化优势，
            高效助力中小电商企业撬动跨境生意，实现从0到1的出海掘金之路。
        </p>
        <mobile-btn></mobile-btn>
    </div>
</template>
<script>
import mobileBtn from '@/components/pages/index/mobile/mobileBtn.vue'
export default {
    components: {
        mobileBtn
    }
}
</script>
<style lang="scss" scoped>
.mobileBanner {
    font-family: PingFangSC-Medium,PingFang SC;
    color:#312F2E;
    text-align: center;
    padding: 40px 24px;
    background: #FFF8EE;
    img {
        width: 273px;
        height: 139px;
        object-fit: cover;
        margin: auto;
    }
    .title {
        font-size: 21px;
        font-weight: 500;
        line-height: 29px;
        margin: 24px 0 14px 0;
    }
    .infos {
        font-size: 14px;
        line-height: 27px;
        text-align: left;
        margin-bottom: 10px;
        letter-spacing: 1px;
    }
}
    
</style>

