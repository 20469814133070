<template>
    <div>
        <div class="fppHeader" id="header">
            <header class="centerBox webHeader">
                <img src="@/assets/images/index/logo.png" alt="" class="logo">
                <div class="nav">
                    <a  
                        :class="{active: activeIndex==index}"
                        v-for="(nav,index) in navArr"
                        :key="index" 
                        @click="locateAt(nav.id,index)">
                        {{nav.name}}
                    </a>
                </div>
                <div class="btn_body">
                    <div class="com_btn" @click="applyCreate">
                        立即建站
                    </div>
                    <div class="login_btn" @click="login">
                        登录
                    </div>
                </div>
            </header>
            <header class="centerBox mobileHeader paddingBox">
                <img v-lazy="require('@/assets/images/index/mobile/logo.png')" alt="" class="logo">
                <div @click="showNav" class="navBtn">
                    <img v-lazy="require('@/assets/images/index/mobile/menu.png')" class="menu" >
                </div>
            </header>
            <!-- 移动端侧边栏 -->
            <menu-nav v-show="showNavBar" ref="navBar"></menu-nav>
        </div>
    </div>
</template>
<script>
import menuNav from '@/components/pages/index/mobile/menu.vue'
export default {
    data() {
        return {
            activeIndex: 0,
            showNavBar: false,
            navArr: [
                {
                    id: 'banner',
                    name: '关于我们'
                },
                {
                    id: 'third',
                    name: '平台优势'
                },
                {
                    id: 'fourth',
                    name: '建站教程'
                },
                {
                    id: 'fifth',
                    name: '特色模板'
                },
            ],
        }
    },
    components: {
        menuNav
    },
    methods: {
        locateAt(id,idx){
            this.activeIndex = idx 
            let domEl =  document.getElementById(id); /*以id命名的锚点*/ 
            // 获取头部的高度
            const headerHeight =  document.getElementById('header').offsetHeight;
            //获取锚点距离头部的距离
            let topy = domEl.offsetTop; 
            domEl = domEl.offsetParent
            if(domEl) {
                topy += domEl.offsetTop;
            }
            //滚动的距离应该减去头部的高度 
            topy-=headerHeight;/*悬浮菜单的高度*/  
            window.scrollTo(0,topy); 
        },
        applyCreate (){
            // window.ga('send', 'event','site building','click button','1')
            this.$router.push({
                path: '/register',
            })
        },
        login (){
            this.$router.push({
                name: 'login'
            })
        },
        showNav() {
            this.showNavBar = !this.showNavBar
            this.$refs.navBar.showNav = true
        }
    },
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/common.scss';
.fppHeader {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 1001;
    background: #fff;
    box-shadow:0px 2px 3px 0px rgba(226,214,203,0.2);
    .logo {
        width: 174px;
        height: 43px;
        object-fit: cover
    }
    .centerBox {
        height: 110px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .nav {
        a {
            margin-right: 38px;
            font-size: 20px;
            font-family:$font;
            font-weight:400;
            color:$gray;
            line-height:28px;
            cursor: pointer;
        }
        .active{
            color: $black;
            font-weight: bold;
        }
    }
    .btn_body{
        width: 329px;
        display: flex;
        justify-content:  space-between;
    }
    .com_btn {
        color: $fff;
        background: $green;
        width: 165px;
        height: 50px;
        line-height: 50px;
        border-radius: 8px;
        text-align: center;
        cursor: pointer;
    }
    .login_btn {
        border: 1px solid $green;
        background: $fff;
        width: 124px;
        height: 50px;
        line-height: 50px;
        border-radius: 8px;
        border: 1px solid $green;
        text-align: center;
        cursor: pointer;
  }
}
@media only screen and (max-width: 1440px) {
    .centerBox {
        max-width: 1050px;
        margin: auto
    }
    .paddingTop {
        height: 83px;
    }
    .fppHeader {
        .centerBox {
            height: 83px;
        }
        .logo{
            width: 149px;
            height: 37px;
            object-fit: cover
        }
        nav {
            a {
                margin-right: 28px;
                font-size: 15px;
                font-family: $font;
                font-weight: 400;
                color:$gray;
                line-height: 21px;
            }
        }
        .btn_body{
            width: 247px;
        }
        .com_btn {
            font-weight: 400;
            font-size: 15px;
            color: $fff;
            width: 124px;
            height: 38px;
            line-height: 38px;
            border-radius: 6px;
        }
        .login_btn {
            font-weight: 400;
            font-size: 15px;
            color: $green;
            width: 93px;
            height: 38px;
            line-height: 38px;
            border-radius: 6px;
  }
    }

}
@media only screen and (max-width: 1366px) {
    .centerBox {
        max-width: 996px;
        margin: auto;
    }
    .fppHeader {
        .centerBox {
            height: 78px;
        }
        .logo{
            width: 141px;
            height: 35px;
            object-fit: cover
        }
        nav {
            a {
                margin-right: 28px;
                font-size: 14px;
                font-family:$font;
                font-weight: 400;
                color:$gray;
                line-height: 20px;
            }
        }
        .btn_body{
            width: 232px;
        }
        .com_btn {
            color: $fff;
            background: $green;
            width: 117px;
            height: 36px;
            line-height: 36px;
            border-radius: 6px;
            text-align: center;
        }
        .login_btn {
            color: $green;
            width: 87px;
            height: 36px;
            line-height: 36px;
            border-radius: 6px;
        }
    }
}
.mobileHeader {
    display: none !important;
}
@media only screen and (max-width: 1440px) {
    .fppHeader .nav a {
        font-size: 17px
    }
}
@media only screen and (max-width: 1100px) {
    .paddingBox {
        padding: 0 24px;
    }
    .webHeader {
        display: none !important;
    }
    .fppHeader {
        .mobileHeader {
            display: flex !important;
            width: 100%;
            height: 56px;
            box-sizing: border-box;
            font-size: 12px;
            color: #84807B;
            box-shadow: 0px 3px 4px 0px rgba(226,214,203,0.2);
            background: #fff;
            text-align: center;
            line-height: 20px;
            position: relative;
            z-index: 100;
            .navBtn {
                width: 40px;
                height: 100%;
                line-height: 56px;
                text-align: right;
                cursor: pointer;
            }
            .logo {
                width: 120px;
                height: 30px;
                object-fit: cover;
            }
            .menu {
                width: 16px;
                height: 14px;
                object-fit: cover;
            }
        }
    }
    
}

</style>


