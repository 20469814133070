<template>
    <div class="templates mobile">
        <bigTitle :title="'精彩模版'"></bigTitle>
        <div class="swiper-container templateSwiper" id="templateSwiper">
            <div class="swiper-wrapper">
                <div class="swiper-slide">
                   <div class="imgs">
                        <img src="@/assets/images/index/tags.png" alt="" class="tags">
                        <img :src="modle1" alt="" class="imgInfo" :data-original="modle1">
                    </div>
                </div>
                <div class="swiper-slide">
                   <div class="imgs">
                        <img src="@/assets/images/index/tags.png" alt="" class="tags">
                        <img :src="modle2" alt="" class="imgInfo" :data-original="modle2">
                    </div>
                </div>
                <div class="swiper-slide">
                   <div class="imgs">
                        <img src="@/assets/images/index/tags.png" alt="" class="tags">
                        <img :src="modle3" alt="" class="imgInfo" :data-original="modle3">
                    </div>
                </div>
                <div class="swiper-slide">
                   <div class="imgs">
                        <img src="@/assets/images/index/tags.png" alt="" class="tags">
                        <img :src="modle4" alt="" class="imgInfo" :data-original="modle4">
                    </div>
                </div>
            </div>
        </div>
        <!-- Add Pagination -->
        <div class="swiper-pagination template-pagination"></div>
        <mobileBtn class="btn"></mobileBtn>
    </div>

</template>

<script>
import Swiper from 'swiper'; 
import 'swiper/dist/css/swiper.min.css';
//模板介绍图片
import type1 from '@/assets/images/index/type_01.jpg'
import type2 from '@/assets/images/index/type_02.jpg'
import type3 from '@/assets/images/index/type_03.jpg'
import type4 from '@/assets/images/index/type_04.jpg'
import bigTitle from '@/components/pages/index/mobile/bigTitle.vue'
import mobileBtn from '@/components/pages/index/mobile/mobileBtn.vue'
import Viewer from "viewerjs";
import 'viewerjs/dist/viewer.css'
  export default {
    name: 'app',
    data() {
      return {
            modle1: type1,
            modle2: type2,
            modle3: type3,
            modle4: type4,
      }
    },
    methods: {
        lookDetail: function () {
            let that = this
            that.viewerBox = new Viewer(document.getElementById('templateSwiper'),{
                url: 'data-original',
                navbar: false,
                fullscreen: true,
            });
        },
    },
    components: {
        bigTitle,
        mobileBtn
    },
    mounted (){
         new Swiper('.templateSwiper', {
            slidesPerView: 1.4,
            spaceBetween: 5,
            centeredSlides: true,
            loop: true,
            pagination: '.template-pagination',
            paginationClickable: true,
        });
        this.$nextTick(()=>{
            this.lookDetail()
        })
    }
  }
</script>

<style lang="scss" scoped>
@import '@/assets/css/common.scss';
.templates {
    padding-bottom: 40px;
    .swiper-container {
        width: 100%;
        padding: 20px 0;
    }
    .swiper-slide {
        @include box();
        justify-content: center;
        flex-direction: column;
        align-items: center;
        text-align: center;
        font-size: 18px;
        background: #fff;
        box-shadow: 0px 4px 25px -9px rgba(226,214,203,1);
        border-radius: 10px;
        border: 1px solid rgba(245,245,255,1);
        transition: 300ms;
        transform: scale(0.8);
        .imgs {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            border: 6px solid #FBE2BE;
            border-radius: 12px;
            position: relative;
            .imgInfo {
                width: 100%;
                height: 100%;
                object-fit: contain;
                border-radius: 6px;
            }
            .tags {
                width: 120px;
                height: 120px;
                object-fit: cover;
                position: absolute;
                left: -15px;
                top: -16px;
            }
        }
    }
    .swiper-slide-active,.swiper-slide-duplicate-active{
        transform: scale(1);
    }
    .template-pagination {
        position: relative;
    }
    .swiper-pagination-bullet {
        width:12px;
        height:4px;
        background:#afaba8;
        border-radius:3px;
        margin: 0 3px;
        top: 10px;
    }
    .swiper-pagination-bullet-active {
        background: #FF6D24;
    }
    .btn {
        margin-top: 30px;
    }
}

    
</style>
