<template>
    <div class="mobileBtn" @click="goSubmit">
        <img v-lazy="require('@/assets/images/index/mobile/iconBtn.png')"> &nbsp;立即建站
    </div>
</template>
<style lang="scss" scoped>
.mobileBtn {
    margin: auto;
    width: 184px;
    height: 55px;
    line-height: 55px;
    background: #54AB8E;
    box-shadow: 0px 6px 21px -8px #84807B;
    border-radius: 28px;
    font-family: PingFang SC,Avenirregular,sans-serif,Microsoft YaHei;
    font-weight: 500;
    color: #fff;
    text-align: center;
    img {
        width: 22px;
        height: 20px;
        object-fit: cover;
        vertical-align: text-bottom;
    }
}
</style>
<script>
export default {
    methods: {
        goSubmit() {
            //跳转至表单填写页面
            this.$router.push({
                path: '/register',
            })
        }
    }
}
</script>
