<template>
    <div class="navBar" v-show="showNav" @click="showNav=false">
        <div class="menu" @click.stop>
            <ul>
                <li  
                    :class="{active: activeIndex==index}"
                    v-for="(nav,index) in navArr"
                    :key="index" 
                    @click="locateAt(nav.id,index)">
                    {{nav.name}}
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            activeIndex: 0,
            showNav: true,
            navArr: [
                {
                    id: 'firstMobile',
                    name: '关于我们'
                },
                {
                    id: 'thirdMobile',
                    name: '平台优势'
                },
                {
                    id: 'fourthMobile',
                    name: '建站教程'
                },
                {
                    id: 'fifthMobile',
                    name: '特色模板'
                },
            ],
        }
    },
    methods: {
        locateAt(id,idx){
            this.showNav = false
            this.activeIndex = idx 
            let domEl =  document.getElementById(id); /*以id命名的锚点*/ 
            // 获取头部的高度
            const headerHeight =  document.getElementById('header').offsetHeight;
            //获取锚点距离头部的距离
            let topy = domEl.offsetTop; 
            domEl = domEl.offsetParent
            if(domEl) {
                topy += domEl.offsetTop;
            }
            //滚动的距离应该减去头部的高度 
            topy-=headerHeight;/*悬浮菜单的高度*/  
            window.scrollTo(0,topy); 
        },
    }
}
</script>

<style lang="scss" scoped>
.navBar {
    position: fixed;
    top: 56px;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 1;
    z-index: 99;
    background: rgba(0,0,0,.1);
    width: 100%;
}
.menu {
    height: 100%;
    background: #fff;
    width: 180px;
    box-sizing: border-box;
    position: absolute;
    right: 0;
    box-shadow:0px 3px 4px 0px rgba(226,214,203,0.2);
    padding: 30px 40px;
    text-align: left;
    font-size: 16px;
    font-family: PingFang SC,Avenirregular,sans-serif,Microsoft YaHei;
    font-weight: 500;
    color:rgba(27,27,29,1);
    line-height: 22px;
    letter-spacing: 1px;
    ul {
        li {
            padding-bottom: 50px;
            cursor: pointer;
            &:hover{
                color: #FF6D24
            }
        }
        .active {
            color: #FF6D24
        }
    }
}
</style>

