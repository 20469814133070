<template>
    <div class="introCard" :class="{ 'greenCard': cardInfo.bgColor }">
        <div class="header">
            <img :src="cardInfo.imgUrl" alt="" class="topImg">
            <p class="title">{{cardInfo.title}}</p>
            <img v-lazy="require('@/assets/images/index/xian.png')" alt="" class="line">
            <p class="intros">{{cardInfo.text}}</p>
        </div>
    </div>
</template>
<script>
import xiane from '@/assets/images/index/xiane.png'
export default {
    data() {
        return {
            xiane: xiane
        }
    },
    props: {
        cardInfo: {
            type: Object,
            default: () => []
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/common.scss';
.introCard {
    flex: 1;
    padding: 20px 16px;
    box-sizing: border-box;
    background: $orangeLiner;
    border-radius: 20px;
    margin-right: 28px;
    &:last-child{
        margin-right: 0px;
    }
    .header {
        text-align: center;
        img {
            width: 68px;
            height: 68px;
            object-fit: cover;
        }
        .intros {
            color: $fff;
            font-size: 16px;
            font-family: $font;
            line-height: 30px;
        }
        .title {
            color: $fff;
            font-size: 23px;
            font-family: $font;
            font-weight: bold;
            color:$fff;
            line-height: 42px;
            margin: 0px 0 6px 0;
        }
        .line {
            width: 78px;
            height: 20px;
            object-fit: contain;
            margin-bottom: 6px;
        }
    }
}
.greenCard {
    background: $greenLiner;
}
</style>
