<template>
    <!-- 弹层 -->
    <div class="markBox" v-show="showMark">
        <img v-lazy="require('@/assets/images/index/closeIcon.png')" alt="" class="close"  @click="hideMark">
        <div class="swiper-container" id="movies">
            <div class="swiper-wrapper">
                <div class="swiper-slide swiper-no-swiping" v-for="(movie,index) in playeroptionList" :key="index">
                    <video-player  
                        ref="videoPlayer"
                        class="video-player vjs-custom-skin"
                        @play="onPlayerPlay($event,index)"
                        :playsinline="true"
                        :options="movie">
                    </video-player>
                </div>
            </div>
        </div>
        <!-- Add Arrows -->
        <div class="swiper-button-next swiper-button-white movieNext"></div>
        <div class="swiper-button-prev swiper-button-white moviePrev"></div>
    </div>
</template>
<script>
import bindImg from '@/assets/images/index/bindingMovie.jpg'
import createImg from '@/assets/images/index/createMovie.jpg'
import decorationImg from '@/assets/images/index/decorationMovie.jpg'
import Swiper from 'swiper'; 
import 'swiper/dist/css/swiper.css'
export default {
    data() {
        return {
            showMark: true,
            showCreate: 0,
            videolist:[
                {
                    poster: createImg,
                    movieUrl: 'https://fpp-seller.papayamobile.com/web/courses01-preparation-products.mp4',
                    type: "video/mp4",
                },
                {
                    poster: decorationImg,
                    movieUrl: 'https://fpp-seller.papayamobile.com/web/courses02-website-decoration.mp4',
                    type: "video/mp4",
                },
                {
                    poster: bindImg,
                    movieUrl: 'https://fpp-seller.papayamobile.com/web/courses03-pixel-payment-method-settings.mp4',
                    type: "video/mp4",
                },
            ],
            playeroptionList: [],
        }
    },
    methods: {
        hideMark() {
            this.showMark = false
            this.closePlayer()
        },
        initSwiper (idx){
            const that = this
            new Swiper('#movies', {
                    observer: true,
                    observeParents: true,
                    initialSlide: idx,
                    prevButton: '.moviePrev',
                    nextButton:'.movieNext',
                    onSlideChangeStart: function(){
                        that.closePlayer();
                    },
                });
        },
        onPlayerPlay (player,index){
            const that = this.$refs.videoPlayer;
            that.forEach((item,idx)=>{
                if(idx !=index){
                    that[idx].player.pause()
                }
            })
        },
        closePlayer() {
            let players = this.$refs.videoPlayer
            players.forEach((item)=>{
                    item.player.pause()
            })
        },
        is_mobile() {
            const regex_match = /(nokia|iphone|android|motorola|^mot-|softbank|foma|docomo|kddi|up.browser|up.link|htc|dopod|blazer|netfront|helio|hosin|huawei|novarra|CoolPad|webos|techfaith|palmsource|blackberry|alcatel|amoi|ktouch|nexian|samsung|^sam-|s[cg]h|^lge|ericsson|philips|sagem|wellcom|bunjalloo|maui|symbian|smartphone|midp|wap|phone|windows ce|iemobile|^spice|^bird|^zte-|longcos|pantech|gionee|^sie-|portalmmm|jigs browser|hiptop|^benq|haier|^lct|operas*mobi|opera*mini|320x320|240x320|176x220)/i;
            const u = navigator.userAgent;
            if (null == u) {
            return true;
            }
            const result = regex_match.exec(u);
            
            if (null == result) {
                return false
            } else {
                return true
            }
        }
    },
    created (){
        //初始化数据
        this.videolist.forEach(item => {
            let videoObj = {
                playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
                autoplay: false, // 如果true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 导致视频一结束就重新开始。
                preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: 'zh-CN',
                aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [
                    {
                        type: item.type,
                        src: item.movieUrl
                    }
                ],
                poster: item.poster, // 你的封面地址
                notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: true,
                    durationDisplay: true,
                    remainingTimeDisplay: false,
                    fullscreenToggle: true,  // 全屏按钮
                }
            }
            this.playeroptionList.push(videoObj)
        });
    }
}
</script>
<style lang="scss" scoped>
.close {
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 100%;
    position: absolute;
    right: 60px;
    top: 30px;
    z-index: 10
}
.center {
    width: 90%;
    position: relative;
    img {
        max-width: 800px;
        height: 100%;
        object-fit: cover;
    }
}
.swiper-container {
    width: 90%;
    .video-player {
        width: 100%;
        height: 100%;
    }
}
.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}
.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
    right: 5%;
    cursor: pointer;
}
.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
    left: 5%;
    cursor: pointer;

}
@media only screen and (max-width: 850px) {
    .swiper-container {
        width: 100%
        // min-width: 660px;
    }
    .swiper-button-next, .swiper-button-prev {
        height: 20px;
    }
    .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
        right: 4%;
        cursor: pointer;
    }
    .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
        left: 4%;
        cursor: pointer;
    }
}
</style>


