<template>
    <div class="bigTitle">
        <span class="yin">“</span>
        <span>&nbsp;&nbsp;{{title}}&nbsp;&nbsp;</span>
        <span class="yin">”</span>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/common.scss';
.bigTitle {
    color: $black;
    font-size: 18px;
    font-weight: bold;
    font-family: $font;
    text-align: center;
    padding: 30px 0 20px 0;
    .yin {
        color: $green;
        font-size: 30px;
        font-weight: bold;
    }
}
</style>

