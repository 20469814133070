<template>
    <div class="funpinpinIndex">
        <!-- 头部 -->
        <my-head></my-head>
        <!-- 移动端侧边栏 -->
        <div class="paddingTop"></div>
        <div class="first" id="first">
            <div class="bannerBox" id="banner">
                <div class="banner centerBox">
                    <div class="intro">
                        <p class="title">
                            <span>
                                中小跨境电商营销第一“站”
                                <!-- <img src="@/assets/images/index/ye.png" alt=""> -->
                                <img v-lazy="require('@/assets/images/index/ye.png')" alt="">
                            </span>
                        </p>
                        <p class="introInfo">
                            FunPinPin是高效连接流量方及支付方政策需求的建站平台，
                            可有效降低违规风险及封站机率、并确保资金的流动性，
                            是中小电商撬动跨境生意优选的出海掘金引擎。平台首创0月租、0佣金的免费服务模式，
                            并依托于全链条海外营销服务领跑者“木瓜移动”的孵化优势，
                            致力于为中小跨境电商提供集“云端建站、网站运营、智能营销、资金管理”为一体的一站式出海解决方案。
                        </p>
                        <div class="search">
                            <input type="text" placeholder="请输入您的邮箱" v-model="emailT">
                            <div class="btn" @click="applyCreate(emailT,'2')">申请建站</div>
                        </div>
                    </div>
                    <div class="introImgBox">
                        <Lottie :options="defaultoptions" :width="700" v-show="true"/>
                        <!-- <div class="introImg">
                            <img src="@/assets/images/index/computer.png" alt="" class="computer">
                            <img src="@/assets/images/index/arrow.png" alt="" class="arrow">
                        </div>     -->
                    </div>
                </div>
            </div>
        </div>
        <!-- 移动端第一模块 -->
        <div class="first-mobile" id="firstMobile">
            <mobile-banner></mobile-banner>
        </div>
        <!-- 第二模块 -->
        <div class="second swiper-container centerBox" id="second">
            <div class="swiper-wrapper">
                <div class="centerBox swiper-slide">
                    <div class="infoImg">
                        <img src="@/assets/images/index/01.png" alt="">
                    </div>
                    <div class="info">
                        <p class="title">让跨境生意触手可及</p>
                        <div class="line">
                            <img src="@/assets/images/index/lines.png" alt="">
                        </div>
                        <p class="text">
                            0元打造专属独立站，高效洞悉消费动态，直链海外头部流量，助您轻松管理全球生意， 
                            拥抱跨境电商新机遇。
                        </p>
                    </div>
                </div>
                <div class="centerBox swiper-slide">
                    <div class="infoImg">
                        <img src="@/assets/images/index/02.png" alt="">
                    </div>
                    <div class="info">
                        <p class="title">助力品牌探索无限可能</p>
                        <div class="line">
                            <img src="@/assets/images/index/lines.png" alt="">
                        </div>
                        <p class="text">
                            平台为跨境电商构建生态赋能体系，提供集“云端建站、网站运营、智能营销、资金管理、定制服务”
                            为一体的一站式出海解决方案。
                        </p>
                    </div>
                </div>
                <div class="centerBox swiper-slide">
                    <div class="infoImg">
                        <img src="@/assets/images/index/03.png" alt="">
                    </div>
                    <div class="info">
                        <p class="title">驱动粉丝长效转化</p>
                        <div class="line">
                            <img src="@/assets/images/index/lines.png" alt="">
                        </div>
                        <p class="text">
                            平台以技术为驱动力，整合顶尖出海资源，可帮助企业精准触达全球买家，打造自己专属的国际化品牌，实现客户沉淀的最优化。
                        </p>
                    </div>
                </div>
            </div>
            <!-- Add Pagination -->
            <div class="swiper-pagination banner-page"></div>
        </div>
        <!-- 移动端第二模块 -->
        <div class="second-mobile" id="secondMobile">
            <goodness></goodness>
        </div>
        <!-- 第三模块 -->
        <div class="third" id="third">
            <p class="bigTitle">
                <span class="yin">“</span>
                <span>&nbsp;&nbsp;为什么选择FunPinPin&nbsp;&nbsp;</span>
                <span class="yin">”</span>
            </p>
            <div class="bg">
                <div class="centerBox">
                    <intro-card 
                        :cardInfo="item" 
                        v-for="(item, index) in introInfo" :key="index" >
                    </intro-card>
                </div>
            </div>
        </div>
        <!-- 移动端第三模块 -->
        <div class="third-mobile" id="thirdMobile">
            <introcardMobile></introcardMobile>
        </div>
        <!-- 第四模块 -->
        <div class="fourth" id="fourth">
            <p class="bigTitle">
                <span class="yin">“</span>
                <span>&nbsp;&nbsp;建站教程·轻松打造你的专属独立站&nbsp;&nbsp;</span>
                <span class="yin">”</span>
            </p>
            <div class="centerBox">
                <div class="left">
                    <p class="title">
                        <span>跨境电商</span>
                        <span class="long">独立站<img v-lazy="require('@/assets/images/index/ye2.png')" alt=""></span>
                        <span>打造全解析</span>
                    </p>
                    <div class="btn" @click="applyCreate('','3')">立即建站</div>
                    <img v-lazy="require('@/assets/images/index/mark.png')" alt="">
                </div>
                <div class="right">
                </div>
                <!-- 视频部分 -->
                <div class="videos">
                    <div class="movies oneMovie" @click="changeMovie(0)">
                        <div>
                            <span class="title">FunPinPin建站教程第一期：</span>
                            <span class="green title">建站前的准备及商品上传</span>
                        </div>
                        <img v-lazy="require('@/assets/images/index/start.jpg')" alt="">
                    </div>
                    <div class="movies" @click="changeMovie(1)">
                        <div>
                            <span class="title">FunPinPin建站教程第二期：</span>
                            <span class="green title">装饰你的独立站</span>
                        </div>
                        <img v-lazy="require('@/assets/images/index/start.jpg')" alt="">
                    </div>
                    <div class="movies" @click="changeMovie(2)">
                        <div>
                            <span class="title">FunPinPin建站教程第三期：</span>
                            <span class="green title">推广渠道及支付账户绑定</span>
                        </div>
                        <img v-lazy="require('@/assets/images/index/start.jpg')" alt="">
                    </div>
                </div>
                <!-- 一个箭头 -->
                <div class="arrows">
                    <img v-lazy="require('@/assets/images/index/arrow.png')" alt="">
                </div>
            </div>
            <!-- <div class="centerBox proCards">
                <pro-card v-for="(proInfo,idx) in proArr" :key="idx" :info="proInfo"></pro-card>
            </div> -->
        </div>
         <!-- 移动端第四模块 -->
        <div class="fourth-mobile" id="fourthMobile">
            <movieSwiper @watchMv="changeMovie"></movieSwiper>
        </div>
        <!-- 第五模块 -->
        <div class="fifth" id="fifth">
            <span class="cicleB"></span>
            <span class="cicle cicleA"></span>
            <span class="cicle cicleC"></span>
            <p class="bigTitle">
                <span class="yin">“</span>
                <span>&nbsp;&nbsp;特色模板&nbsp;&nbsp;</span>
                <span class="yin">”</span>
            </p>
            <p class="theme">来自FunPinPin精心打磨的模版主题，助您完美展现国际化品牌面貌，有效提升用户转化</p>
            <div class="imgBox centerBox">
                <div class="imgs">
                    <img v-lazy="require('@/assets/images/index/tags.png')" alt="" class="tags">
                    <img v-lazy="modle1" alt="" class="imgInfo" :data-original="modle1">
                </div>
                <div class="imgs">
                    <img v-lazy="require('@/assets/images/index/tags.png')" alt="" class="tags">
                    <img v-lazy="modle2" alt="" class="imgInfo" :data-original="modle2">
                </div>
            </div>
            <div class="imgBox centerBox">
                <div class="imgs">
                    <img v-lazy="require('@/assets/images/index/tags.png')" alt="" class="tags">
                    <img v-lazy="modle3" alt="" class="imgInfo" :data-original="modle3">
                </div>
                <div class="imgs">
                    <img v-lazy="require('@/assets/images/index/tags.png')" alt="" class="tags">
                    <img v-lazy="modle4" alt="" class="imgInfo" :data-original="modle4">
                </div>
            </div>
        </div>
        <!-- 移动端第五模块 -->
        <div class="fifth-mobile" id="fifthMobile">
            <templateSwiper></templateSwiper>
        </div>
        <!-- 按钮 -->
        <!-- <div class="creatBtn" @click="applyCreate('')">立即建站</div> -->
        <!--  第六模块 -->
        <div class="sixth" id="sixth">
            <div class="centerBox startBox">
                <div class="left">
                    <p>马上建站，探索出海智慧掘金旅程</p>
                    <div class="search">
                        <input type="text" placeholder="请输入您的邮箱" v-model="email">
                        <div class="btn" @click="applyCreate(email,'4')">申请建站</div>
                    </div>
                </div>
                <div class="right">
                    <img v-lazy="require('@/assets/images/index/computerP.jpg')" alt="" class="computer">
                    <img v-lazy="require('@/assets/images/index/arrow.png')" alt="" class="arrow">
                </div>
            </div>
        </div>
        <!-- 尾部 -->
        <my-footer></my-footer>
        <!-- 视频播放弹层 -->
        <movie ref="movies" v-show="showMovies"></movie>
    </div>
</template>
<script>
import header from '@/components/pages/index/header.vue';
import footer from '@/components/pages/index/footer.vue';
import introCard from '@/components/pages/index/introCard.vue'
import movie from '@/components/pages/index/markMovie.vue'
import Lottie from '@/components/pages/index/animate.vue';
import * as animationData from '@/assets/json/pages/index/banner.json';

//移动端第一模块
import mobileBanner from '@/components/pages/index/mobile/banner.vue'
import goodness from '@/components/pages/index/mobile/goodnessSwiper.vue'
import movieSwiper from '@/components/pages/index/mobile/movieSwiper.vue'
import templateSwiper from '@/components/pages/index/mobile/templateSwiper.vue'
import introcardMobile from '@/components/pages/index/mobile/introCard.vue'
// import proCard from '@/components/proCard.vue'

// 介绍相关图片
import xiane from '@/assets/images/index/xiane.png'
import trans from '@/assets/images/index/trans.png'
import money from '@/assets/images/index/money.png'
import anxin from '@/assets/images/index/anxin.png'
import sale from '@/assets/images/index/sale.png'

// 产品相关图片
import JZ from '@/assets/images/index/JZ.png'
import jc from '@/assets/images/index/jc.png'
//模板介绍图片
import type1 from '@/assets/images/index/type_01.jpg'
import type2 from '@/assets/images/index/type_02.jpg'
import type3 from '@/assets/images/index/type_03.jpg'
import type4 from '@/assets/images/index/type_04.jpg'

import Swiper from 'swiper'; 
import Viewer from "viewerjs";
import 'viewerjs/dist/viewer.css'
import Vue from 'vue'
import VideoPlayer from 'vue-video-player'
import ('video.js/dist/video-js.css')
import ('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)
export default {
    data() {
        return {
            email: '',
            emailT: '',
            showMovies: false,
            introInfo: [
                {
                    title: '无限额',
                    text: '背靠头部流量，广告授信额度无上限，返点政策更具优势',
                    bgColor: false,
                    imgUrl: xiane
                },
                {
                    title: '享安心',
                    text: '高效连接流量方及支付方政策需求，可有效降低违规风险及封站机率、并确保资金的流动性',
                    bgColor: true,
                    imgUrl: anxin
                },
                {
                    title: '高转化',
                    text: 'One page checkout，更亲近用户的支付流程，有效减少客户流失率',
                    bgColor: false,
                    imgUrl: trans
                },
                {
                    title: '0月租',
                    text: '0月租，0佣金，0成本转移，一分钟轻松拥有海外独立站',
                    bgColor: true,
                    imgUrl: money
                },
                {
                    title: '懂营销',
                    text: '基于营销平台原生打造的建站工具，有效驱动ROAS最大化',
                    bgColor: false,
                    imgUrl: sale
                }
            ],
            proArr: [
                {
                    title: '建站教程 | 自定义你的站',
                    imgUrl: JZ
                },
                {
                    title: '建站教程 | 产品导入',
                    imgUrl: jc
                },
                {
                    title: '建站教程 | 弃单召回',
                    imgUrl: jc
                },
                {
                    title: '建站教程 | GA流量统计',
                    imgUrl: JZ
                },
            ],
            overseaArr: [
                {
                    title: '建站教程 | 自定义你的站',
                    tags: [
                        { bgClass: 'blue', name: 'Facebook' },
                        { bgClass: 'reds', name: '营销干货' },
                    ],
                    imgUrl: JZ,
                },
                {
                    title: '建站教程 | 自定义你的站',
                    imgUrl: JZ,
                    tags: [
                        { bgClass: 'reds', name: 'Google' },
                        { bgClass: 'blue', name: '营销干货' },
                    ],
                },
                {
                    title: '建站教程 | 自定义你的站',
                    imgUrl: JZ,
                    tags: [
                        { bgClass: 'blue', name: 'Facebook' },
                        { bgClass: 'light', name: '营销干货' },
                    ],
                },
                {
                    title: '建站教程 | 自定义你的站',
                    imgUrl: JZ,
                    tags: [
                        { bgClass: 'blue', name: 'Facebook' },
                        { bgClass: 'light', name: '营销干货' },
                    ],
                },
            ],
            modle1: type1,
            modle2: type2,
            modle3: type3,
            modle4: type4,
            imgArr: [
                type1,
                type2,
                type3,
                type4,
            ],
            viewerBox: false,
            defaultoptions: { animationData: animationData },
        }
    },
    components: {
        introCard,
        movie,
        mobileBanner,
        goodness,
        introcardMobile,
        movieSwiper,
        templateSwiper,
        // proCard,
        Lottie,
        'my-head':header,
        'my-footer':footer
    },
    mounted() {
         new Swiper('#second', {
            direction: 'vertical',
            slidesPerView: 1,
            spaceBetween: 60,
            mousewheel: false,
            observer:true, //修改swiper自己或子元素时，自动初始化swiper
            observeParents:true,//修改swiper的父元素时，自动初始化swiper
            autoplay: 5000,
            pagination: '.banner-page',
            paginationClickable: true,
            loop: true
        });
        this.$nextTick(()=>{
            this.lookDetail()
        })
        
    },
    methods: {
        changeMovie (idx){
            this.showMovies = true
            //特色模板切换大图
            this.$refs['movies'].showMark = true
            this.$refs['movies'].showCreate = idx
            this.$refs['movies'].initSwiper(idx)
        },
        lookDetail: function () {
            let that = this
            that.viewerBox = new Viewer(document.getElementById('fifth'),{
                url: 'data-original',
                navbar: false,
                fullscreen: true,
            });
        },
        applyCreate (email,name){
            // window.ga('send', 'event','site building','click button',name)
            //申请 建站
            if (email) {
                this.$router.push({
                    path: '/register',
                    query:{
                        email: email
                    }
                })
                return
            }
            this.$router.push({
                path: '/register',
            })
        }
    },
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/banner.scss';
.swiper-container {
    height: 360px;
    overflow: hidden;
    position: relative;
}
.swiper-slide {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    .infoImg {
        img {
            width: 337px;
            height: 307px;
            object-fit: contain;
        }
        
    }
}
#index2 {
    li {
        margin-right: 15px;
        margin-bottom: 15px;
        width: 25%;
        height: 200px;
        display: inline-block;
    }
    li:hover{
        cursor: zoom-in;
    }
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}


</style>

