<template>
    <div class="proCard">
        <img :src="info.imgUrl" alt="">
        <div class="cardbottom">
            <div class="tags" v-if="info.tags">
                <div class="tag"
                    :class="item.bgClass" 
                    v-for="(item,indx) in info.tags" :key="indx">
                    {{item.name}}
                </div>
            </div>
            <p class="title">
                {{info.title}}
            </p>
            <p class="see">
                点击查看 <img v-lazy="require('@/assets/images/index/rightBig.png')" alt="" class="arrow"> 
            </p>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        info: {
            type: Object,
            default: () => {}
        }
    }
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/common.scss';
.proCard {
    width: 100%;
    padding-bottom: 17px;
    box-shadow:0px 13px 38px -14px rgba(243,226,192,1);
    border-radius:8px;
    margin-right: 30px;
    overflow: hidden;
    .cardbottom {
        padding: 0 9px;
        box-sizing: border-box;
        text-align: left;
    }
    .title {
        font-size: 16px;
        font-family: $font;
        font-weight: 500;
        color: $black;
        line-height: 22px;
        margin: 17px 0;
    }
    .see {
        color: $grays;
        font-size: 12px;
        .arrow {
            width: 5px;
            height: 7px;
        }
    }
    &:last-child{
        margin-right: 0;
    }
    img {
        width: 100%;
        height: 134px;
        object-fit: cover;
    }
}
.tags {
    @include box();
    justify-content: flex-start;
    margin-top: 14px;
}
.tag {
    padding: 0px 10px;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    border-radius: 4px;
    margin-right: 10px;
    &:last-child {
        margin-right: 0;
    }
}
.blue {
    background: rgba(87,142,255,0.2);
    color: $blue;
}
.reds {
    background: #FFB798;
    color: #EA4335;
}
.light {
    background: rgba(255,176,134,0.1);
    color: #FFB798;
}
</style>

