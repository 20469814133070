<template>
    <div class="proCardBox">
        <bigTitle :title="'为什么选择FunPinPin'"></bigTitle>
        <div class="card" v-for="(card,index) in prodoctArr" :key="index">
            <div class="cardBody" >
                <div class="left">
                    <img v-lazy="card.imgUrl" alt="" class="topImg">
                    <p class="title">{{card.title}}</p>
                    <img v-lazy="require('@/assets/images/index/xian.png')" alt="" class="line">
                </div>
                <div class="right" :style="{ color: card.color }">
                    <p> {{card.info}} </p>
                </div>
            </div>
            <img v-lazy="card.bgUrl" alt="" class="bgImg">
        </div>
        <mobileBtn class="btn"></mobileBtn>
    </div>
</template>
<script>
import xiane from '@/assets/images/index/xiane.png'
import anxin from '@/assets/images/index/anxin.png'
import trans from '@/assets/images/index/trans.png'
import money from '@/assets/images/index/money.png'
import sale from '@/assets/images/index/sale.png'
// 背景图片
import orangeBg from '@/assets/images/index/mobile/orangeBg.png'
import greenBg from '@/assets/images/index/mobile/greenBg.png'
//头部组件
import bigTitle from '@/components/pages/index/mobile/bigTitle.vue'
import mobileBtn from '@/components/pages/index/mobile/mobileBtn.vue'
export default {
    data() {
        return {
            xiane,
            prodoctArr :[
                {
                    title: '无限额',
                    info: '背靠头部流量，广告授信额度无上限，返点政策更具优势',
                    imgUrl: xiane,
                    bgUrl: orangeBg,
                    color: '#602B14'
                },
                {
                    title: '享安心',
                    info: '高效连接流量方及支付方政策需求，可有效降低违规风险及封站机率、并确保资金的流动性',
                    imgUrl: anxin,
                    bgUrl: greenBg,
                    color: '#0A412F'
                },
                {
                    title: '高转化',
                    info: 'One page checkout，更亲近用户的支付流程，有效减少客户流失率',
                    imgUrl: trans,
                    bgUrl: orangeBg,
                    color: '#602B14'
                },
                {
                    title: '0月租',
                    info: '0月租，0佣金，0成本转移，一分钟轻松拥有海外独立站',
                    imgUrl: money,
                    bgUrl: greenBg,
                    color: '#0A412F'
                },
                {
                    title: '懂营销',
                    info: '基于营销平台原生打造的建站工具，有效驱动ROAS最大化',
                    imgUrl: sale,
                    bgUrl: orangeBg,
                    color: '#602B14'
                }
            ]
        }
    },
    components: {
        bigTitle,
        mobileBtn
    }
}
</script>


<style lang="scss" scoped>
@import '@/assets/css/common.scss';
.proCardBox {
    background: #FFF1DC;
    padding: 0 24px 40px 20px;
}
.card {
    position: relative;
    height: 120px;
    overflow: hidden;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 20px 0;
    &:nth-child(2) {
        margin-top: 0;
    }
    .cardBody {
        height: 120px;
        @include box();
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 2;
        text-align: center;
        .left {
            flex: 3;
            @include box();
            justify-content: center;
            align-items: center;
            flex-direction: column;
            flex-wrap: nowrap;
            font-size: 16px;
            font-family: $font;
            font-weight: 600;
            color: $fff;
            line-height: 22px;
            font-weight: bold;
            .topImg {
                width: 42px;
                height: 42px;
                object-fit: cover;
                margin-bottom: 10px;
            }
            .line {
                width: 27px;
                height: 7px;
                object-fit: cover;
                margin-top: 10px;
            }
        }
        .right {
            flex: 5;
            @include box();
            justify-content: center;
            align-items: center;
            margin: 0 20px;
            font-size: 13px;
            font-family: $font;
            font-weight: 400;
            color:#0A412F;
            line-height: 24px;
            text-align: left;
        }
    }
    .bgImg {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
    }
}
.btn {
    margin-top: 30px
}
</style>

