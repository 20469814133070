<template>
    <div class="movies mobile">
        <bigTitle :title="'轻松打造你的专属独立站'"></bigTitle>
        <div class="swiper-container movieSwiper">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(proInfo,idx) in proArr" :key="idx">
                    <movie-card :info="proInfo"></movie-card>
                </div>
            </div>
        </div>
        <!-- Add Pagination -->
        <div class="swiper-pagination movie-pagination"></div>
    </div>

</template>

<script>
import bigTitle from '@/components/pages/index/mobile/bigTitle.vue'
import movieCard from '@/components/pages/index/proCard.vue'
import Swiper from 'swiper'; 
import createImg from '@/assets/images/index/mobile/createImg.png'
import beautyImg from '@/assets/images/index/mobile/beauty.png'
import bindImg from '@/assets/images/index/mobile/bind.png'
import 'swiper/dist/css/swiper.min.css';
  export default {
    name: 'app',
    components: {
        movieCard,
        bigTitle
    },
    data() {
      return {
          proArr: [
                {
                    title: 'FunPinPin建站教程第一期：建站前的准备及商品上传',
                    imgUrl: createImg
                },
                {
                    title: 'FunPinPin建站教程第二期：装饰你的独立站',
                    imgUrl: beautyImg
                },
                {
                    title: 'FunPinPin建站教程第三期：推广渠道及支付账户绑定',
                    imgUrl: bindImg
                },
            ],
      }
    },
    methods: {
    },
    mounted (){
        let that = this
         new Swiper('.movieSwiper', {
            slidesPerView: 1.5,
            spaceBetween: 10,
            centeredSlides: true,
            loop: true,
            pagination: '.movie-pagination',
            paginationClickable: true,
            onClick: function(swiper){
                that.$emit('watchMv',swiper.realIndex)
            }
        });
    }
  }
</script>

<style lang="scss" scoped>
@import '@/assets/css/common.scss';
.movies {
    background: #FFFAF3;
    padding-bottom: 40px;
    .swiper-container {
        width: 100%;
        height: auto;
        padding: 20px 0 30px 0;
    }
    .swiper-slide {
        @include box();
        justify-content: center;
        flex-direction: column;
        align-items: center;
        text-align: center;
        font-size: 18px;
        background: #fff;
        box-shadow:0px 4px 25px -9px rgba(226,214,203,1);
        border-radius:10px;
        border:1px solid rgba(245,245,255,1);
        transition: 300ms;
        transform: scale(0.9);
        .infoImg {
            img {
                width: 220px;
                height: 201px;
                object-fit: cover;
            }
        }
        .info {
            padding: 0 22px;
            .title {
                color: $black;
                font-weight: bold;
                font-size: 16px;
            }
            .line {
                width: 58px;
                height: 10px;
                margin: 10px auto;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .text {
                font-size: 16px;
                color: $gray;
                line-height: 27px;
            }
        }
    }
    .swiper-slide-active,.swiper-slide-duplicate-active{
        transform: scale(1);
    }
    .swiper-pagination {
        position: relative;
    }
    .swiper-pagination-bullet {
        width:12px;
        height:4px;
        background:#afaba8;
        border-radius:3px;
        margin: 0 3px;
        top: 10px;
    }
    .swiper-pagination-bullet-active {
        background: #FF6D24;
    }
}

    
</style>
